import React from "react";
import { styled } from "../../../stitches.config";
import { Layout } from "../../layout";
import { Colors, Hero } from "../../components/shared/Hero";
import { Button } from "../../components/shared/Button";
import MainImage from "../../images/tbizshops/tbizshop-green.svg";
import { BreadCrumbs } from "../../components/shared/BreadCrumbs";
import { routes } from "../../routes/routes";
import { useI18next, useTranslation } from "gatsby-plugin-react-i18next";
import { Checklist } from "../../components/shared/Checklist";
import { Seo } from "../../components/shared/Seo";

const shopsFeaturesItems = [
  {
    text: "tbizShop.card.text1",
  },
  {
    text: "tbizShop.card.text2",
  },
  {
    text: "tbizShop.card.text3",
  },
  {
    text: "tbizShop.card.text4",
  },
  {
    text: "tbizShop.card.text5",
  },
  {
    text: "tbizShop.card.text6",
  },
];

const TbizShop: React.FC = () => {
  const { t } = useTranslation();
  const bgColor = Colors.transparent;
  const { navigate } = useI18next();

  return (
    <Layout>
      <Seo
        title={t("tbizShop.title")}
        description={t("tbizShop.mainText1")}
        image={MainImage}
      />
      <Hero pic={{ wrap: Image, src: MainImage }} backgroundColor={bgColor}>
        <BreadCrumbs
          root={{ link: routes.tbizedi.edi, title: "tbizedi" }}
          current="tbizshops"
        />
        <Heading color={bgColor}>{t("tbizShop.title")}</Heading>
        <HeroDesc color={bgColor}>{t("tbizShop.mainText1")}</HeroDesc>
        <HeroDesc color={bgColor}>{t("tbizShop.mainText2")}</HeroDesc>
      </Hero>
      <StyledChecklist
        list={shopsFeaturesItems}
        title={"tbizShop.card.title"}
      />
      <Container>
        <ButtonsWrap>
          <Button onClick={() => navigate(routes.contact.contactForm)}>
            {t("tbizShop.button")}
          </Button>
        </ButtonsWrap>
        <Text>{t("tbizShop.lastText")}</Text>
      </Container>
    </Layout>
  );
};

export default TbizShop;

const Container = styled("div", {
  maxWidth: "1280px",
  margin: "0 auto",
  padding: "32px 16px 80px",
  "@md": {
    padding: "40px 0 80px",
  },
});

const Text = styled("p", {
  fontWeight: "400",
  fontSize: "13px",
  lineHeight: "20px",
  color: "$screedDisplayText",
  margin: "0 auto",
  maxWidth: "600px",
  "@md": {
    margin: 0,
  },
});

const Image = styled("img", {
  objectFit: "contain",
  maxWidth: "464px",
  width: "90%",
});

const Heading = styled("h1", {
  color: "$white",
  fontSize: "32px",
  fontWeight: 700,
  lineHeight: "40px",
  margin: "30px 0 0",

  variants: {
    color: {
      transparent: {
        color: "$text",
      },
    },
  },

  "@md": {
    margin: "0",
    fontSize: "48px",
    lineHeight: "56px",
    color: "$heading",
  },
});

const HeroDesc = styled("p", {
  color: "$white",
  fontSize: "17px",
  fontWeight: 400,
  lineHeight: "24px",
  margin: "12px 0px",

  variants: {
    color: {
      transparent: {
        color: "$text",
      },
    },
  },
  "&:last-child": {
    margin: "0 0 12px",
  },
  "@md": {
    color: "$text",
  },
});

const ButtonsWrap = styled("div", {
  display: "flex",
  flexDirection: "column",
  maxWidth: "600px",
  margin: "0 auto 40px",
  "@md": {
    margin: "0 0 40px",
    flexDirection: "row",
  },
});

const StyledChecklist = styled(Checklist, {
  maxWidth: 640,
  ul: {
    flexWrap: "nowrap",
    flexDirection: "column",
    li: {
      flexBasis: "auto",
    },
  },
  "@md": {
    maxWidth: "unset",
  },
});
