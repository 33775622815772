import { useTranslation } from "gatsby-plugin-react-i18next";
import React from "react";
import { styled } from "../../../stitches.config";

type Props = {
  title: string;
  list: {
    text: string;
  }[];
  backgroundColor?: "gray";
  description?: string;
  fixed?: boolean;
  nowrap?: boolean;
};

export const Checklist: React.FC<Props> = ({
  list,
  title,
  backgroundColor,
  description,
  fixed,
  ...props
}) => {
  const { t } = useTranslation();
  return (
    <ListContainer backgroundColor={backgroundColor} {...props}>
      <Title>{t(title)}</Title>
      {description && <ItemText>{t(description)}</ItemText>}
      <Box fixed={fixed}>
        {list.map((el, i) => (
          <ItemBox key={i} fixed={fixed}>
            <Item>{t(el.text)}</Item>
          </ItemBox>
        ))}
      </Box>
    </ListContainer>
  );
};

const ListContainer = styled("div", {
  padding: "32px 16px",
  margin: "0 auto",
  borderRadius: "8px",
  width: "100%",
  maxWidth: "1280px",
  background: "$primaryBackground",
  "@md": {
    padding: "40px",
    margin: "60px auto 0",
    width: 1280,
  },
  variants: {
    backgroundColor: {
      gray: {
        background: "$listBackgroundGray",
      },
    },
  },
});

const Title = styled("h1", {
  margin: "0 0 12px",
  fontSize: "20px",
  lineHeight: "24px",
  fontWeight: 700,
  color: "$text",
  "@md": {
    fontSize: "24px",
    lineHeight: "28px",
  },
});

const ItemBox = styled("li", {
  margin: "0 0 12px",
  "@md": {
    flexBasis: "50%",
  },
  "&:last-child": {
    margin: 0,
  },
  variants: {
    fixed: {
      true: {
        "@md": {
          maxWidth: 504,
        },
      },
    },
  },
});

const Item = styled("p", {
  margin: 0,
  fontSize: "17px",
  lineHeight: "24px",
  fontWeight: 400,
  color: "$text",
  "@md": {
    fontWeight: 700,
  },
});

const Box = styled("ul", {
  marginTop: 24,
  paddingLeft: 16,
  display: "flex",
  flexDirection: "column",
  "@md": {
    marginTop: 32,
    flexDirection: "row",
    flexWrap: "wrap",
    justifyContent: "space-between",
  },
  variants: {
    fixed: {
      true: {
        display: "flex",
        flexWrap: "wrap",
      },
    },
  },
});

const ItemText = styled("div", {
  fontWeight: "400",
  fontSize: "15px",
  lineHeight: "20px",
  margin: "0 0 12px",
  "@md": {
    fontSize: "17px",
    lineHeight: "24px",
  },
});
