import {
  StyledComponent,
  StyledComponentType,
} from "@stitches/react/types/styled-component";
import React, {
  FunctionComponent,
  ReactNode,
  Ref,
  useEffect,
  useRef,
  useState,
} from "react";
import { styled } from "../../../stitches.config";

export enum Colors {
  orange = "#E59933",
  green = "#A2C617",
  blue = "#01498E",
  transparent = "transparent",
}

type Props = {
  children: ReactNode;
  pic: {
    wrap: StyledComponentType<any>;
    src: string;
  };
  backgroundColor: Colors;
  paddingTopBottomOff?: boolean;
};

export const Hero: React.FC<Props> = ({
  children,
  pic: { wrap: Image, src },
  backgroundColor,
  paddingTopBottomOff,
  ...props
}) => {
  const [backgroundVisible, setBackgroundVisible] = useState<"none" | "block">(
    "none"
  );

  return (
    <HeroSection css={{ backgroundColor }} {...props}>
      <StyledTextSection paddingTopBottomOff={paddingTopBottomOff}>
        <TextWrapper>{children}</TextWrapper>
      </StyledTextSection>
      <StyledPicSection paddingTopBottomOff={paddingTopBottomOff}>
        <ImageWrapper
          css={{
            "&::after": {
              backgroundColor,
              display: backgroundVisible,
            },
          }}
        >
          <Image src={src} onLoad={() => setBackgroundVisible("block")} />
        </ImageWrapper>
      </StyledPicSection>
    </HeroSection>
  );
};

const TextWrapper = styled("div", {
  display: "flex",
  flexDirection: "column",
  width: "100%",
  maxWidth: "640px",
  whiteSpace: "pre-line",
  margin: "0",
  float: "right",
});

const ImageWrapper = styled("div", {
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  position: "relative",
  width: "100%",
  maxWidth: "640px",
  margin: "0",
  "@md": {
    alignItems: "flex-end",
    "& > img": { zIndex: 1 },
    "&::after": {
      content: "",
      position: "absolute",
      width: "60%",
      height: "80%",
      borderRadius: "32px",
      transform: "translate(0%, 20%)",
      right: 0,
    },
  },
});

const HeroSection = styled("section", {
  display: "flex",
  flexDirection: "column-reverse",
  margin: "0 auto",
  width: "100%",
  "@md": {
    padding: "0px",
    backgroundColor: "$white !important",
    flexDirection: "row",
    overflow: "hidden",
  },
});

const StyledTextSection = styled("div", {
  padding: "0px 16px 0 16px",
  margin: "0 auto",
  "@md": {
    padding: "86px 0 0",
    flexBasis: "50%",
    position: "relative",
  },
  variants: {
    paddingTopBottomOff: {
      true: {
        padding: "0px",
      },
    },
  },
});

const StyledPicSection = styled("div", {
  padding: "86px 16px 50px",
  margin: "0 auto",
  "@md": {
    padding: "86px 0 0",
    flexBasis: "50%",
  },
  "@mx": {
    padding: "86px 0 0",
  },
  variants: {
    paddingTopBottomOff: {
      true: {
        padding: "0px",
      },
    },
  },
});
