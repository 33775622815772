import { Link, useTranslation } from "gatsby-plugin-react-i18next";
import React from "react";
import { styled } from "../../../stitches.config";

type Link = { link: string; title: string };

type Props = {
  root: Link;
  current: string;
  showOnMobile?: true;
  color?: "white" | "black";
};

export const BreadCrumbs = ({ root, current, showOnMobile, color }: Props) => {
  const { t } = useTranslation();

  return (
    <Wrap color={color} showOnMobile={showOnMobile}>
      <StyledLink to={root.link}>{t(root.title)}</StyledLink>
      <span>{" > "}</span>
      <StyledCurrent>{t(current)}</StyledCurrent>
    </Wrap>
  );
};

const StyledLink = styled(Link, {
  fontSize: "14px",
  lineHeight: "19px",
  cursor: "pointer",
  fontWeight: 400,
  color: "$text",
  textDecoration: "none",
  marginRight: "5px",
});

const StyledCurrent = styled("span", {
  fontSize: "14px",
  lineHeight: "19px",
  fontWeight: 700,
  color: "$text",
  marginLeft: "5px",
  userSelect: "none",
});

const Wrap = styled("div", {
  fontSize: "14px",
  lineHeight: "19px",
  color: "$text",
  marginBottom: "18px",
  variants: {
    showOnMobile: {
      true: { display: "flex" },
      false: {
        display: "none",
        "@md": {
          display: "flex",
        },
      },
    },
    color: {
      white: {
        "@md": {
          [`& ${StyledLink}`]: {
            color: "$white",
          },
          [`& ${StyledCurrent}`]: {
            color: "$white",
          },
          "& > span": {
            color: "$white",
          },
        },
      },
      black: {
        "@md": {
          [`& ${StyledLink}`]: {
            color: "$heading",
          },
          [`& ${StyledCurrent}`]: {
            color: "$heading",
          },
          "& > span": {
            color: "$heading",
          },
        },
      },
    },
  },
  defaultVariants: {
    showOnMobile: "false",
  },
});
